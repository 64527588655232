import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import image1 from "../../assets/images/about/Tool Room.png";
import image2 from "../../assets/images/about/Waxing.jpg";
import image3 from "../../assets/images/about/Shell building.jpg";
import image4 from "../../assets/images/about/Dewaxing.jpg";
import image6 from "../../assets/images/about/Heat treatment.jpg";
import image5 from "../../assets/images/about/Melting.jpg";
import image7 from "../../assets/images/about/Inspection.jpeg";
import image8 from "../../assets/images/about/Fittling.jpg";
import './AboutFive.css'


  const AboutFive = () => {
  return (
    <>
    <div className="section section-padding-t90-b100">
            <div className="container">
                <div className="row row-cols-lg-3 row-cols-md-3 row-cols-1 mb-n6" >

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail" >
                        <img src={image1} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info" >
                            <h3 className="title">TOOL ROOM</h3>
                            <p style={{textAlign:'justify'}}>
                            
                               <li>Single cavity,multi cavity manual & auto tool made
                            from aluminium.</li>
                               <li>Tools are stored in rack with identification and
                            traceability of place</li>
                            <li> Powershape software for 3D modeling.</li>
                          <li> Simulation software - Soft Cast </li>
                            
                            </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail" >
                        <img src={image2} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info" >
                            <h3 className="title">WAX INJECTION</h3>
                            <p style={{textAlign:'justify'}}>
                            
                            <li>
                        Well equipped setup with controlled atmospheric area.
                      </li>
                      <li>
                        Single injection autopress with capacity of 12, 20 & 35
                        MT.
                      </li>
                            
                            </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail" >
                        <img src={image3} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info">
                            <h3 className="title">SHELL BUILDING</h3>
                            <p style={{textAlign:'justify'}}>
                            
                            <li>
                        High strength refractory material used after receiving
                        inspection.
                      </li>
                      <li>
                        Primary & back up room facilitate with rain fall
                        sander, high RPM slurry mixture, slurry drums, fluidized
                        bed, high axial fans.
                      </li>
                      <li>
                        De-Humidifier & central airconditioning system
                        installed to control temperature & humidity.
                      </li>
                            
                            </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail" >
                        <img src={image4} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info" >
                            <h3 className="title">DEWAXING</h3>
                            <p style={{textAlign:'justify'}}>
                            
                            <li>
                          IBR approved autoclave (Dewax Boiler) for dewaxing
                          purpose.
                        </li>
                        <li>
                          Has the bore diameter of 900mm X bed length 1000mm &
                          oprating pressure range upto 10 bar.
                        </li>
                        <li>Make : Energypack boilers.</li>
                            
                            </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail" >
                        <img src={image5} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info" >
                            <h3 className="title">MELTING</h3>
                            <p style={{textAlign:'justify'}}>
                            
                            <li>Induction Furnace 75 KW-25 KGS-2 Nos.</li>
                        <li>Induction Furnace 75 KW-75 KGS-1 Nos.</li>
                        <li>Induction Furnace 175 KW-200 KGS-2 Nos.</li>
                        <li>
                        Liquid metal temperature controlled via pyrometer & Temperature Controller.
                        </li>
                        {/* <li>
                        All equipment & temperature indicators/controllers calibrated regularly.
                        </li> */}
                            
                            </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail" >
                        <img src={image6} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info">
                            <h3 className="title">HEAT TREATMENT</h3>
                            <p style={{textAlign:'justify'}}>
                            
                          <li>Pit Type hardening furnace capacity 300 KG per batch.</li>
                        <li>Bogie type furnace capacity 800 KG per batch.</li>
                        <li>Process - Annealing, Normalizing, Stress Relieving,
                          Hardening, Tempering & Solution Annealing .
                        </li>
                        </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail">
                        <img src={image7} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info" >
                            <h3 className="title">INSPECTION</h3>
                            <p style={{textAlign:'justify'}}>
                            
                            <li>
                                Castings are inspected dimensionally according
                                to customer drawings.
                              </li>
                              <li>
                                Performed NDT activity according to customers PO
                                requirement.
                              </li>
                              <li>Visual inspection 100%.</li>
                            
                            </p>
                        </div>
                </div>

                <div className="work" style={{marginTop: '10px',marginBottom: '10px'}}>
                    <div className="thumbnail">
                        <img src={image8} style={{ }} height="300px" width="410px" alt="work" />
                    </div>
                        <div className="info" >
                            <h3 className="title">FETTLING</h3>
                            <p style={{textAlign:'justify'}}>
                            
                            <li>Knockout facility.</li>
                              <li>Shot blasting machine.</li>
                                <li>Rotary table type</li>
                                <li>Hanger type</li>
                                <li>Tumbler type</li>                              
                              <li> Polishing machine</li>
                              <li>Pneumatic machine</li>
                              <li> Wheel Cutting machine</li>
                            
                            </p>
                        </div>
                </div>


              </div>
            </div>
    </div>

    
    
    </>
  );
};

export default AboutFive;