

import { useState } from 'react';
import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import image1 from '../../src/assets/images/Foundary/foundary1.jpg';
import image2 from '../../src/assets/images/Foundary/foundary2.jpg';
import image3 from '../../src/assets/images/Foundary/foundary3.jpg';
import image4 from '../../src/assets/images/Foundary/foundary4.jpg';
import image5 from '../../src/assets/images/Foundary/foundary5.jpg';
import image6 from '../../src/assets/images/Foundary/foundary6.jpg';
import image7 from '../../src/assets/images/Foundary/foundary7.jpg';
import image8 from '../../src/assets/images/Foundary/foundary8.jpg';
import image9 from '../../src/assets/images/Foundary/foundary9.jpg';
import image10 from '../../src/assets/images/Foundary/foundary10.jpg';
import image12 from '../../src/assets/images/Foundary/foundary12.jpg';
import image13 from '../../src/assets/images/Foundary/foundary13.jpg';
import image14 from '../../src/assets/images/Foundary/foundary14.jpg';
import image15 from '../../src/assets/images/Foundary/foundary15.jpg';
import image11 from '../../src/assets/images/Foundary/foundary11.jpg';
import './Foundary.css';



// const Foundary = () => {
 
  

//   return (
//     <React.Fragment>
//       <SEO title="Thirumala || Gallery" />
//       <Header />
//       <Breadcrumb
//         image="images/bg/aboutImage.jpg"
//         title="Welocome To Our Foundary!!"
//         content="Home"
//         contentTwo="Foundary"
//       />
      
//       <div className={`section section-padding-t90 section-padding-bottom`}>
//             <div className="container">


//                 <div className="row row-cols-lg-4  ">

//                 <div key={""} className="col mb-6"  >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image1} width='300px' height='300px' alt=""/>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
         
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image2} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image3} width='300px' height='300px' alt="" className='hover:scale-110 '/>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image4} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image5} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image6} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image7} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image8} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image9} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image10} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image11} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image12} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image13} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image14} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                     <div key={""} className="col mb-6" >
                
           
//                         <div className="content">
//                             {/* <h3 className="title">{data.title}</h3> */}
//                             <div className="desc">
//                              {/* <p>{data.desc}</p> */}
//                              <img src={image15} width='300px' height='300px' alt=""></img>
//                             </div>
//                             {/* <Link to={data.link} className="link">{data.pageLink}</Link> */}
//                          </div>
                      
//                     </div>
//                 </div>
  
//     </div>
//   </div>


             
        
        
  
//       <Footer />
//       <ScrollToTop />
//     </React.Fragment>
//   );
// }

// export default Foundary;






// Import other images similarly
import './Foundary.css';

const Foundary = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openImage = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };
  const navigateImage = (direction) => {
    if (selectedImageIndex !== null) {
        let newIndex;
        if (direction === 'prev') {
            newIndex = (selectedImageIndex - 1 + images.length) % images.length;
        } else {
            newIndex = (selectedImageIndex + 1) % images.length;
        }
        setSelectedImageIndex(newIndex);
    }
};

  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15];
  

  return (
    <React.Fragment>
      <SEO title="Thirumala || Gallery" />
      <Header />
      <Breadcrumb
        image="images/bg/foundry_bread.png"
        title="Welcome To Our Foundry!!"
        content="Home"
        contentTwo="Foundary"
      />
      
      <div className={`section section-padding-t90 section-padding-bottom`}>
        <div className="container">
          <div className="row row-cols-lg-4">
            {/* Render images */}
            {images.map((image, index) => (
              <div key={index} className={`col mb-6 ${selectedImageIndex !== null && selectedImageIndex !== index ? 'transparent' : ''}`} onClick={() => openImage(index)}>
                <img src={image} width='300px' height='300px' alt="" className={selectedImageIndex !== null && selectedImageIndex === index ? 'selected' : ''} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Image Pop-up */}
      {selectedImageIndex !== null && (
        <div className="image-popup">
          <div className="image-popup-inner">
            
            <button className="close" onClick={closeImage}>X</button>
            <button className="arrow prev" onClick={() => navigateImage('prev')}>&lt;</button>
            <img src={images[selectedImageIndex]} alt="" className="centered" />
            <button className="arrow next" onClick={() => navigateImage('next')}>&gt;</button>
          </div>
        </div>
      )}

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
}

export default Foundary;


