import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem className="accordion-item" id="one">
                    <AccordionTitle className="accordion-title" id="one"><strong>CHOICE OF ALLOY SELECTION</strong></AccordionTitle>
                    <AccordionContent className="accordion-content" id="one">Alloy selection offers virtually unlimited range of strength to weight ratios.</AccordionContent>
                </AccordionItem>
                <AccordionItem className="accordion-item" id="two">
                    <AccordionTitle className="accordion-title" id="two"><strong>DESIGN FLEXIBILITY</strong></AccordionTitle>
                    <AccordionContent className="accordion-content" id="two">We offer flexibility in design allowing unlimited geometry required when machining a part from bar stock.</AccordionContent>
                </AccordionItem>
                <AccordionItem className="accordion-item" id="three">
                    <AccordionTitle className="accordion-title" id="three"><strong>COST REDUCTION</strong></AccordionTitle>
                    <AccordionContent className="accordion-content" id="three">We offer high precision finished products in the least time and cost.</AccordionContent>
                </AccordionItem>
                <AccordionItem className="accordion-item" id="four">
                    <AccordionTitle className="accordion-title" id="four"><strong>INCREASE MANUFACTURING CAPACITY</strong></AccordionTitle>
                    <AccordionContent className="accordion-content" id="four">We offer a ‘net shape’ manufacturing process with innovative engineering.</AccordionContent>
                </AccordionItem>
                <AccordionItem className="accordion-item" id="five">
                    <AccordionTitle className="accordion-title" id="five"><strong>CASTING CAPABILITY</strong></AccordionTitle>
                    <AccordionContent className="accordion-content" id="five">We offer high precision finished products in the least time and cost.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
