import React from 'react'
import {NavLink} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li className="">
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                   
                </li>
                <li className='has-children'>
                    <NavLink to={process.env.PUBLIC_URL + "/About"}><span className="menu-text">About Us</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/About"}><span className="menu-text">About</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/About-advantages"}><span className="menu-text">Advantages</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/Gallery"}><span className="menu-text">Gallery</span></NavLink></li>
                       </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/Service/defence"}><span className="menu-text">Products</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/Service/defence"}><span className="menu-text">Defence Components</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/service/surgical"}><span className="menu-text">Surgical Instruments</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/service/oilandgas"}><span className="menu-text">Oil And Gas Industry Parts</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/service/automobile"}><span className="menu-text">Automobile Parts</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/service/machine"}><span className="menu-text">Machine Tools</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/testing-facility"}><span className="menu-text">In-House Facilities</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/testing-facility"}><span className="menu-text">Testing Facilities</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/in-house-facilities/machining`}><span className="menu-text">Machining Facility</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/quality-certificate"}><span className="menu-text">Quality Certificate</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/quality-certificate"}><span className="menu-text">Quality Certificate</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/Material"}><span className="menu-text">Material</span></NavLink></li>
                        {/* <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li> */}
                    </ul>
                </li>
                <li>
                    {/* <NavLink to={process.env.PUBLIC_URL + "/career"}><span className="menu-text">Career</span></NavLink> */}
                    {/* <NavLink to={process.env.PUBLIC_URL + "/career"}><span className="menu-text">Blog</span></NavLink> */}
                    <NavLink to={process.env.PUBLIC_URL + "/csr"}><span className="menu-text">CSR</span></NavLink>
                </li>

                {/* <li className="has-children"> */}
             
                    {/* <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog Grid</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-classic"}><span className="menu-text">Blog Classic</span></NavLink></li>
                        {/* <li><NavLink to={process.env.PUBLIC_URL + "/blog-details"}><span className="menu-text">Blog Details</span></NavLink></li> */}
                         {/* <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li> */}
                    {/* </ul>  */}
                {/* </li> */}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar
