import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import AccordionWrap from '../../components/Accordion/AccordionWrap.jsx';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import image1 from '../../assets/images/Products/Factory.jpg'
import './Faq.css'

const Faq = () => {
    // const [scale] = useState(1.04);
    // const sceneEl = useRef(null);
    // useEffect(() => {
    //     const parallaxInstance = new Parallax(sceneEl.current, {
    //     relativeInput: true,
    //     })
        
    //     parallaxInstance.enable();

    //     return () => parallaxInstance.disable();

    // }, [])
    return (
        <div className="faq-section section section-padding-top bg-primary-blue">
            <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 mb-n6">
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="faq-content">
                            <SectionTitleTwo 
                                // subTitle="Frequently asked questions"
                                title="Why Choose Us"
                            />

                            <AccordionWrap />
                            
                        </div>
                    </div>

                    <div className="col mb-6 ps-xl-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area faq-image-area">
                            
                            <img src={image1}  height="600px"  style={{borderRadius: '20px'}}></img>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;
