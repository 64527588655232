import {useEffect,  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import CareerCorporateDivision from './pages/CareerContentone'
import CareerContentTwo from "./pages/CareerContentTwo"
import CareerContentThree from "./pages/CareerContentThree";
import Foundary from "./pages/Foundary";
import BlogGrid1 from "./pages/BlogGrid1";
const HomeOne = lazy(() => import("./pages/HomeOne"));
const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const HomeThree = lazy(() => import("./pages/HomeThree"));
const Service = lazy(() => import("./pages/Service"));
const ServiceOne = lazy(() => import("./pages/ServiceOne"));
const ServiceTwo = lazy(() => import("./pages/ServiceTwo"));
const ServiceThree = lazy(() => import("./pages/ServiceThree"));
const ServiceFour = lazy(() => import("./pages/ServiceFour"));
const ServiceFive = lazy(() => import("./pages/ServiceFive"));
const Work = lazy(() => import("./pages/Work"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const WorkTwo = lazy(()=> import("./pages/WorkTwo"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogClassic = lazy(() => import("./pages/BlogClassic"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));
const Contact = lazy(() => import("./pages/Contact"));
const AboutOne =lazy(() => import ("./pages/AboutOne"));
const Gallery =lazy(() => import ("./pages/Gallery"));
const OurCustomer = lazy (()=>import ("./pages/OurCustomer"));
const Material = lazy (()=>import ("./pages/Material"));
const Career = lazy (()=>import ("./pages/Career"));
const About =lazy (()=>import ("./pages/About"));
const Investments =lazy (()=>import ("./pages/Investments"));




function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
                <Routes>
                  <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-one"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} element={<HomeTwo/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} element={<HomeThree/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/OurCustomer"}`} element={<OurCustomer/>}/>

                  <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service"}`} element={<Service/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/Service/defence"}`} element={<ServiceOne/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service/surgical"}`} element={<ServiceTwo/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service/oilandgas"}`} element={<ServiceThree/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service/automobile"}`} element={<ServiceFour/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service/machine"}`} element={<ServiceFive/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/testing-facility"}`} element={<Work/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/in-house-facilities/machining"}`} element={<WorkTwo/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/quality-certificate"}`} element={<BlogGrid/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/csr"}`} element={<BlogClassic/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} element={<BlogTag/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} element={<BlogCategories/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}element={<BlogDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/About-advantages"}`} element={<AboutOne/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/Gallery"}`} element={<Gallery/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/Material"}`} element={<Material/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/Career"}`} element={<Career/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/CareerCorporateDivision"}`} element={<CareerCorporateDivision/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/CareerContentTwo"}`} element={<CareerContentTwo/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/CareerContentThree"}`} element={<CareerContentThree/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/Foundary"}`} element={<Foundary/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/Investments"}`} element={<Investments/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/BlogGrid1"}`} element={<BlogGrid1/>}/>


                </Routes>
            </Suspense>
        </NavScrollTop>
      </Router>
  );
}

export default App;
