import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import WorkData from '../../data/work/workDetails.json';
import WorkItemTwo from '../../components/Work/WorkItemTwo.jsx';
import image1 from '../../assets/images/project/spectro_analysis.jpg'
import image2 from '../../assets/images/project/machanical testing.jpg'
import image3 from '../../assets/images/project/HardnessTesting.jpg'
import image4 from '../../assets/images/project/MpiMachine.jpg'
import image5 from '../../assets/images/project/DiyPentrationMachine.jpg'
import image6 from '../../assets/images/project/radiographyMachine.jpg'
import image7 from '../../assets/images/project/cmmMachine.jpg'
import image8 from '../../assets/images/project/inspection.jpg'
import './WorkContainer.css'
import { useState,useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const WorkContainer = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const images = [image1,image2,image3,image4,image5,image6,image7,image8];
  

        
        const loadImage = (imageSrc) => {
          return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = imageSrc;
              img.onload = resolve;
              img.onerror = reject;
          });
      };

      const loadAllImages = async () => {
          try {
              await Promise.all(images.map(loadImage));
              setLoading(false); // All images loaded successfully
          } catch (error) {
              console.error('Error loading images:', error);
              setLoading(false); // Set loading to false even if there's an error
          }
      };

      loadAllImages();

      // Cleanup function
      return () => setLoading(true); // Set loading to true when the component is unmounted or re-rendered
  }, []);

  if (loading) {
    return (
        <div className="spinner-containerf " style={{padding:"45%", color:"blue"}}>
            <div className="container">
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">
                    <div className="spinner-container ">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            </div>
        </div>
    );
}
    return (
        <div className="section section-padding-t90-b100 ">
            <div className="container">

                <SectionTitle
                    headingOption="title fz-32"
                    title="Testing Facilities"
                />

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6 ">

                <div className="work">
                    <div className="thumbnailar" >
                        <img src={image1} alt="work" />
                    </div>
                        <div className="infor">
                            <h3 className="title">SPECTRO ANALYSIS</h3>
                            <p className="desc" style={{color:'black'}}>Fe And Co Base Spectrometer Having 27 Elements With Facility Of N Analysis. CRM Samples Of
                            <ol>
                               <li>Low Alloy Steel</li>
                               <li>Martensitic Steel</li>
                               <li>Austenitic Stainless Steel</li>
                               <li>Duplex Steel</li>
                               <li>Cobalt Base Alloy</li>
                            </ol>
                            </p>
                        </div>
                </div>

                <div className="work">
                    <div className="thumbnailar">
                        <img src={image2} alt="work" />
                    </div>
                        <div className="infor" >
                            <h3 className="title">MECHANICAL TESTING</h3>
                            <p className="desc" style={{color:'black'}}>
                            <ol>
                            <li>Universal Testing Machine.</li> 
                            <li>Impact Testing Machine.</li>
                            </ol>
                            </p>
                            
                        </div>
                </div>

                <div className="work">
                    <div className="thumbnailar" >
                        <img src={image3}  alt="work" />
                    </div>
                        <div className="infor" >
                            <h3 className="title">HARDNESS TESTING</h3>
                            <p className="desc" style={{color:'black'}}>
                            <ol>
                            <li>Rockwell Hardness Tester.</li>
                            <li>Brinell Hardness Tester.</li>
                            </ol>
                            </p>
                        </div>
                </div>

                <div className="work">
                    <div className="thumbnailar">
                        <img src={image4}  alt="work" />
                    </div>
                        <div className="infor">
                            <h3 className="title">MPI TESTING</h3>
                            <p className="desc" style={{color:'black'}}>Magnetic Particle Inspection Is An Inspection Method Used To Identify Defects On The Surface Of Castings By Running A Magnetic Current Through It.</p>
                        </div>
                </div>

                {/* <div className="work">
                    <div className="thumbnailar">
                        <img src={image5}  alt="work" />
                    </div>
                        <div className="infor" >
                            <h3 className="title">DYE PENENTRATE TESTING</h3>
                            <p className="desc" style={{color:'black'}}>Dye Penentrate Testing It Is A Non-destructive Testing Widely Used To Detect The Flaws On Castings.</p>
                        </div>
                </div> */}

                <div className="work">
                    <div className="thumbnailar">
                        <img src={image6}  alt="work" />
                    </div>
                        <div className="infor" >
                            <h3 className="title">RADIOGRAPHY MACHINE</h3>
                            <p className="desc" style={{color:'black'}}>
                                Nablaccredited Lab : ISO/IEC/17025/2017
                                Certificate No. : TC9054
                                Model : XXQ2505
                                Make : Dangdong
                                Power : 250KV, 5MA
                                Penentration Power : 40mm Thickness</p>
                        </div>
                </div>

                <div className="work">
                    <div className="thumbnailar" >
                        <img src={image7}  alt="work" />
                    </div>
                        <div className="infor">
                            <h3 className="title">CMM MACHINE</h3>
                            <p className="desc" style={{color:'black'}}>
                            Make - Accurate India- Spectra - 500X600X400MM
                            Resolution : 0.0005mm Repeatability - 0.002mm</p>
                        </div>
                </div>

                <div className="work">
                    <div className="thumbnailar">
                        <img src={image8}  alt="work" />
                    </div>
                        <div className="infor">
                            <h3 className="title">INSPECTION</h3>
                            <p className="desc" style={{color:'black'}}>
                            Castings Are Inspected Dimensionally According To Customer Drawings.
                            <ol> 
                            <li>Performed Ndt Activity According To Customers Po Requirement.</li>
                            <li>Visual Inspection 100%.</li>
                            </ol>
                            </p>
                        </div>
                </div>
                    
                </div>

            </div>
        </div>
    )
}

export default WorkContainer;
