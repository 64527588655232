import React from 'react'
import SEO from '../components/SEO.jsx';
import Header from "../partials/header/Header.jsx";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb.js';
import Footer from '../container/Footer/Footer.js';
import ScrollToTop from '../components/ScrollToTop.jsx';
import './CareerContentone.css';
import Spinner from 'react-bootstrap/Spinner';
import { useState,useEffect } from 'react';

const CareerCorporateDivision = () => {


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const images = [];


      
      const loadImage = (imageSrc) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageSrc;
            img.onload = resolve;
            img.onerror = reject;
        });
    };

    const loadAllImages = async () => {
        try {
            await Promise.all(images.map(loadImage));
            setLoading(false); // All images loaded successfully
        } catch (error) {
            console.error('Error loading images:', error);
            setLoading(false); // Set loading to false even if there's an error
        }
    };

    loadAllImages();

    // Cleanup function
    return () => setLoading(true); // Set loading to true when the component is unmounted or re-rendered
}, []);

if (loading) {
  return (
      <div className="spinner-containerf " style={{padding:"45%",color:"blue"}}>
          <div className="container">
              <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">
                  <div className="spinner-container ">
                      <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                      </Spinner>
                  </div>
              </div>
          </div>
      </div>
  );
}

  return (
    <React.Fragment>
    <SEO title="Thirumala || Career > Career Corporate Division" />
    <Header />
    <Breadcrumb 
        image="images/bg/newsletter.jpg"
        // title="We are an agency located in New York"
        title=" Corporate Division"
        content="Home"
        contentTwo="Corporate Division"
    

    />
    <div className='no-career'>
   <div className='no-career-found'>
      <h1 className='career'>No Careers Found !</h1>
    </div>
    </div>
   
<Footer />
    <ScrollToTop />
</React.Fragment>
  )
}

export default CareerCorporateDivision
